<template>
  <div>
    <b-card>
      <b-form inline>
        <b-form-group label="Aggregation">
          <b-form-radio-group
            v-model="selectedType"
            :options="typeOptions"
            name="radio-options"
          />
        </b-form-group>
        <b-form-group label="Time Range">
          <b-form-select
            v-if="selectedType == 'month'"
            v-model="selectedMonth"
            size="sm"
            :options="monthSelectOptions"
          />
          <b-form-select
            v-if="selectedType == 'quarter'"
            v-model="selectedQuarter"
            size="sm"
            :options="quarterSelectOptions"
          />
          <date-range-picker
            v-if="selectedType == 'day'"
            v-model="timeRange"
            :selectable-ranges="['last3Days', 'last7Days', 'last14Days', 'last30Days', 'thisMonth', 'lastMonth', 'last60Days', 'last90Days', 'last180Days', 'last365Days']"
            :max-selectable-days="365"
          />
        </b-form-group>
      </b-form>

    </b-card>

    <b-card>
      <ApexDetailMtreeRawDataDaily
        v-if="selectedType == 'day'"
        :asup="asup"
        :asset="asset"
        :time-range="timeRange"
      />
      <ApexDetailMtreeRawDataMonthlyQuarter
        v-if="selectedType == 'month' || selectedType == 'quarter'"
        :asup="asup"
        :asset="asset"
        :time="timeSelection"
      />
    </b-card>

  </div>
</template>
<script>
import {
  BCard, BForm, BFormGroup, BFormRadioGroup, BFormSelect,
} from 'bootstrap-vue'

import DateRangePicker from '@/components/dateRangePicker/DateRangePicker.vue'
import ApexDetailMtreeRawDataDaily from './ApexDetailMtreeRawDataDaily.vue'
import ApexDetailMtreeRawDataMonthlyQuarter from './ApexDetailMtreeRawDataMonthlyQuarter.vue'

export default {
  components: {
    BCard,
    BForm,
    BFormGroup,
    BFormRadioGroup,
    BFormSelect,
    DateRangePicker,
    ApexDetailMtreeRawDataDaily,
    ApexDetailMtreeRawDataMonthlyQuarter,
  },
  props: {
    asup: {
      type: Object,
      default: () => {},
    },
    asset: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      selectedType: 'day',
      typeOptions: [
        { text: 'Per day', value: 'day' },
        { text: 'Per month', value: 'month' },
        { text: 'Per quarter', value: 'quarter' },
      ],
      selectedMonth: null,
      selectedQuarter: null,
      isLoading: false,
      timeRange: {
        startDate: this.$moment().subtract(30, 'days').toDate(),
        endDate: new Date(),
      },
    }
  },
  computed: {
    timeSelection() {
      return {
        type: this.selectedType,
        year: this.selectedType === 'month' ? this.selectedMonth.year : this.selectedQuarter.year,
        month: this.selectedMonth.month,
        quarter: this.selectedQuarter.quarter,
      }
    },
    monthSelectOptions() {
      const monthsSelectionListCount = 12

      const result = []
      for (let i = 0; i <= monthsSelectionListCount; i += 1) {
        const date = this.$moment().add(i * -1, 'months')
        result.push({
          value: {
            month: date.month() + 1,
            year: date.year(),
          },
          text: date.format('MMMM YYYY'),
        })
      }

      return result
    },
    quarterSelectOptions() {
      const quartersSelectionListCount = 4

      const result = []
      for (let i = 0; i <= quartersSelectionListCount; i += 1) {
        const date = this.$moment().add(i * -1, 'quarter')
        result.push({
          value: {
            quarter: date.quarter(),
            year: date.year(),
          },
          text: date.format('[Q]Q YYYY'),
        })
      }

      return result
    },
    asupId() {
      if (this.asup) {
        return this.asup.id
      }

      return null
    },
  },
  mounted() {
    this.selectedMonth = this.monthSelectOptions[0].value
    this.selectedQuarter = this.quarterSelectOptions[0].value
  },
}
</script>
